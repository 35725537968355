import React, { useEffect, useRef, useState } from 'react';
import { SectionHeader } from '../SectionHeader';
import { FeaturedServiceItem } from '../FeaturedServiceItem';
import styled, { css } from 'styled-components';
import { device } from '../../theme';

const StyledServiceItems = styled.section`
  margin-top: 50px;
`;

const StyledServiceItemsGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 100px;
  @media ${device.tablet} {
    /* grid-template-columns: repeat(2, 1fr); */
  }
`;

interface SSHProps {
  headerVisible: boolean;
}
const StyledSectionHeader = styled(SectionHeader)<SSHProps>`
  opacity: 0;
  ${({ headerVisible }) =>
    headerVisible &&
    css`
      opacity: 1;
      animation: fadeInSectionHeader ease 1s;

      @keyframes fadeInSectionHeader {
        0% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}
`;
interface Props {
  services: any;
}
const Services = ({ services }: Props) => {
  const headerRef = useRef<any | null>(null);
  const [headerVisible, setHeaderVisible] = useState(false);
  const handleScroll = (e: any) => {
    if (headerRef.current) {
      var rect = headerRef.current.getBoundingClientRect();
      if (rect.top - document.documentElement.clientHeight < 0) {
        setHeaderVisible(true);
      }
    }
  };
  useEffect(() => {
    if (headerRef.current) {
      var rect = headerRef.current.getBoundingClientRect();
      if (rect.top - document.documentElement.clientHeight < 0) {
        setHeaderVisible(true);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <StyledServiceItems>
      <StyledSectionHeader
        headerVisible={headerVisible}
        ref={headerRef}
        forwardedAs="h2"
      >
        Services
      </StyledSectionHeader>
      <StyledServiceItemsGrid>
        {services.map((serviceItem: any) => (
          <FeaturedServiceItem
            key={serviceItem.node.id}
            serviceItem={serviceItem}
          />
        ))}
      </StyledServiceItemsGrid>
    </StyledServiceItems>
  );
};

export default Services;
