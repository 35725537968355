import React from 'react';
import { Layout } from '../components/Layout';
import { Hero } from '../components/Hero';
import { LatestArticles } from '../components/LatestArticles';
import { LatestPortfolioItems } from '../components/LatestPortfolioItems';
import styled from 'styled-components';
import { device } from '../theme';
import { SEO } from '../components/SEO';
import { Testimonials } from '../components/Testimonials';
import { Services } from '../components/Services';

const StyledLayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 600px auto auto;
  gap: 20px;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const StyledHeroGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 50px;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

interface Props {
  pageContext: any;
}

const Index = ({ pageContext }: Props) => {
  const { edges: articles } = pageContext.storiArticles.data.allStoriArticle;
  const {
    edges: portfolioItems,
  } = pageContext.portfolioItems.data.allStoriPortfolioItem;
  const testimonials = pageContext.allStoriTestimonialItemMdx;
  const { allStoriServiceItemMdx } = pageContext;

  return (
    <Layout>
      <SEO />
      <StyledLayoutWrapper>
        <StyledHeroGrid>
          <LatestPortfolioItems portfolioItems={portfolioItems} />
          <Hero />
        </StyledHeroGrid>
        <Services services={allStoriServiceItemMdx} />
        <Testimonials testimonials={testimonials} />
        <LatestArticles articles={articles} />
      </StyledLayoutWrapper>
    </Layout>
  );
};

export default Index;
