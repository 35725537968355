import React, { useEffect, useState, useRef } from 'react';
import { ArticleItem } from '../ArticleItem';
import { LinkButton } from '../LinkButton';
import { SectionHeader } from '../SectionHeader';
import styled, { css } from 'styled-components';
import { device } from '../../theme';

interface Props {
  articles: any;
}

interface SLAProps {
  sectionVisible: boolean;
}
const StyledLatestArticles = styled.section<SLAProps>`
  opacity: 0;
  ${({ sectionVisible }) =>
    sectionVisible &&
    css`
      opacity: 1;
      animation: fadeInSection ease 1s;

      @keyframes fadeInSection {
        0% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}
`;

const StyledLatestArticleGrid = styled.section`
  display: grid;
  gap: '30px';
  margin-top: '30px';
  grid-template-rows: 'fit-content auto';
`;

const StyledArticleGrid = styled.div`
  display: grid;
  gap: 30px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;

  @media ${device.tabletS} {
    grid-template-columns: 1fr;
  }
`;
const LatestArticles = ({ articles }: Props) => {
  const [sectionVisible, setSectionVisible] = useState(false);
  const ArticleList = useRef<HTMLDivElement | null>(null);
  const handleScroll = (e: any) => {
    if (ArticleList.current) {
      var rect = ArticleList.current.getBoundingClientRect();
      if (rect.top - document.documentElement.clientHeight < 0) {
        setSectionVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <StyledLatestArticles sectionVisible={sectionVisible} ref={ArticleList}>
      <StyledLatestArticleGrid id="Latest_Articles">
        <SectionHeader as="h2">Latest Articles</SectionHeader>
        <StyledArticleGrid>
          {articles.slice(0, 4).map((item: any) => {
            const { node: article } = item;
            return (
              <ArticleItem
                key={article.id}
                sliceExcerpt={85}
                article={article}
              />
            );
          })}
        </StyledArticleGrid>
      </StyledLatestArticleGrid>
      <LinkButton to="/articles">Read more Articles</LinkButton>
    </StyledLatestArticles>
  );
};

export default LatestArticles;
