import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { device } from '../../theme';
import { Link } from 'gatsby';

const StyledPortfolioItem = styled.div`
  height: 400px;
  display: grid;
  gap: 15px;
  grid-template-rows: 2fr 1fr;

  @media ${device.tablet} {
    height: 400px;
  }
`;

const StyledPortfolioItemGrid = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: minmax(100px, 310px) minmax(100px, 1fr);
  grid-template-rows: 350px;
  a:nth-child(1) {
    animation: scaleUpFirstPortItem ease 1s;
  }
  @keyframes scaleUpFirstPortItem {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  a:nth-child(2) {
    animation: scaleUpSecondPortItem ease 1s;
  }

  @keyframes scaleUpSecondPortItem {
    0% {
      transform: scale(0);
    }
    35% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @media ${device.tablet} {
    grid-template-rows: 250px;
  }
`;

const StyledPortfolioItemGridRowTwo = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 250px;

  @media ${device.tablet} {
    grid-template-rows: 150px;
  }

  a:nth-child(1),
  a:nth-child(3) {
    animation: scaleUpFirstPortRow2Item ease 1s;
  }
  @keyframes scaleUpFirstPortRow2Item {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  a:nth-child(2) {
    animation: scaleUpSecondPortRow2Item ease 1s;
  }

  @keyframes scaleUpSecondPortRow2Item {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

interface SIProps {
  index: number;
}
const StyledImage = styled(Img)<SIProps>`
  transition: all 0.2s ease 0s;
  border-radius: 5px;
  height: 100%;
`;

const StyledImageLink = styled(Link)`
  transition: all 0.5s ease 0s;
  &:hover,
  &:focus {
    filter: brightness(0.8);
  }
`;
interface Props {
  portfolioItems: any;
}

const LatestPortfolioItems = ({ portfolioItems }: Props) => {
  return (
    <StyledPortfolioItem>
      <StyledPortfolioItemGrid>
        {portfolioItems.slice(0, 2).map((item: any, index: number) => {
          const { featuredImage, slug, id, title } = item.node;
          return (
            <StyledImageLink key={id} to={slug}>
              <StyledImage
                title={title}
                index={index}
                fluid={featuredImage.childImageSharp.fluid}
              />
            </StyledImageLink>
          );
        })}
      </StyledPortfolioItemGrid>
      <StyledPortfolioItemGridRowTwo>
        {portfolioItems.slice(2, 5).map((item: any, index: number) => {
          const { featuredImage, slug, id, title } = item.node;
          return (
            <StyledImageLink key={id} to={slug}>
              <StyledImage
                title={title}
                index={index + 2}
                fluid={featuredImage.childImageSharp.fluid}
              />
            </StyledImageLink>
          );
        })}
      </StyledPortfolioItemGridRowTwo>
    </StyledPortfolioItem>
  );
};

export default LatestPortfolioItems;
