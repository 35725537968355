import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';
import { ProfilePicture } from '../ProfilePicture';
import { themeVariables } from '../../theme';

const StyledTestimonialItem = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${({ theme }) => theme.cardBG};
  border-radius: ${themeVariables.values.cardBorderRadius};
`;
const StyledTestimonialImage = styled(ProfilePicture)`
  margin-right: 30px;
`;
const StyledTestimonialContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
`;

const StyledTestimonialTitle = styled.h3`
  margin: 0;
`;
interface Props {
  testimonial: any;
}
const TestimonialItem = ({ testimonial }: Props) => {
  const { title, body, featuredImage, from } = testimonial;
  return (
    <StyledTestimonialItem>
      <StyledTestimonialImage fluid={featuredImage.childImageSharp.fluid} />
      <StyledTestimonialContent>
        <StyledTestimonialTitle>{title}</StyledTestimonialTitle>
        <MDXProvider components={{}}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        <div>- {from}</div>
      </StyledTestimonialContent>
    </StyledTestimonialItem>
  );
};

export default TestimonialItem;
