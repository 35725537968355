import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import TestimonialItem from './TestimonialItem';
import { SectionHeader } from '../SectionHeader';
import { device } from '../../theme';

interface STGProps {
  gridMarginLeft: number;
}
const StyledTestimonialsGrid = styled.div<STGProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-left: 0;
  transition: all 0.2s ease 0s;

  @media ${device.tablet} {
    grid-template-columns: repeat(4, calc(100vw - 20px));
    gap: 30px;
    margin-left: ${({ gridMarginLeft }) => gridMarginLeft + 'px'};
  }
`;

interface STLProps {
  sectionVisible: boolean;
}

const StyledTestimonialList = styled.div<STLProps>`
  @media ${device.tablet} {
    width: calc(100vw - 20px);
    overflow: hidden;
  }
  opacity: 0;
  ${({ sectionVisible }) =>
    sectionVisible &&
    css`
      opacity: 1;
      animation: fadeInSection ease 1s;

      @keyframes fadeInSection {
        0% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}
`;

const StyledIndicatorWrapper = styled.div`
  align-items: center;
  justify-content: center;
  padding: 10px;
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
  div + div {
    margin-left: 10px;
  }
`;

interface SIIProps {
  active: boolean;
}
const StyledItemIndicator = styled.div<SIIProps>`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.cardBG};
  border-radius: 50%;
  ${({ active }) =>
    active &&
    css`
      filter: brightness(0.5);
    `}
`;
interface Props {
  testimonials: any;
}
const Testimonials = ({ testimonials }: Props) => {
  const [touchStart, setTouchStart] = useState(0);
  const [gridMarginLeft, setGridMarginLeft] = useState(0);
  const [currentItem, setCurrentItem] = useState(1);
  const [sectionVisible, setSectionVisible] = useState(false);
  const TestimonialList = useRef<HTMLDivElement | null>(null);
  const onTouchStartHandler = (e: any) => {
    setTouchStart(e.touches[0].pageX);
  };

  const onTouchEndHandler = (e: any) => {
    const touchEndX = e.changedTouches[0].pageX;
    if (touchEndX < touchStart) {
      if (currentItem < testimonials.length) {
        if (TestimonialList.current) {
          setGridMarginLeft(
            gridMarginLeft - TestimonialList.current?.offsetWidth - 30
          );
          setCurrentItem(currentItem + 1);
        }
      }
    } else {
      if (currentItem > 1) {
        if (TestimonialList.current) {
          setGridMarginLeft(
            gridMarginLeft + TestimonialList.current?.offsetWidth + 30
          );
          setCurrentItem(currentItem - 1);
        }
      }
    }
  };

  const changeCurrentItem = (index: number) => {
    setCurrentItem(index + 1);
    if (TestimonialList.current && index + 1 !== currentItem) {
      // Move the items to the left
      setGridMarginLeft(
        -TestimonialList.current?.offsetWidth * index - 30 * index
      );
    }
  };

  const handleResize = () => {
    console.log('resize');
    //setCurrentItem(1);
    if (TestimonialList.current) {
      setGridMarginLeft(
        -TestimonialList.current?.offsetWidth * (currentItem - 1) -
          30 * (currentItem - 1)
      );
    }
  };

  const handleScroll = (e: any) => {
    if (TestimonialList.current) {
      var rect = TestimonialList.current.getBoundingClientRect();
      if (rect.top - document.documentElement.clientHeight < 0) {
        setSectionVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <StyledTestimonialList
      sectionVisible={sectionVisible}
      ref={TestimonialList}
    >
      <SectionHeader as="h2">Testimonials</SectionHeader>
      <StyledTestimonialsGrid
        onTouchStart={e => onTouchStartHandler(e)}
        onTouchEnd={e => onTouchEndHandler(e)}
        gridMarginLeft={gridMarginLeft}
      >
        {testimonials.map((testimonial: any) => {
          return (
            <TestimonialItem
              key={testimonial.node.id}
              testimonial={testimonial.node}
            />
          );
        })}
      </StyledTestimonialsGrid>
      <StyledIndicatorWrapper>
        {testimonials.map((testimonial: any, index: number) => {
          return (
            <StyledItemIndicator
              key={`indicator_${index}_${testimonial.node.id}`}
              onClick={e => changeCurrentItem(index)}
              active={index + 1 === currentItem}
            />
          );
        })}
      </StyledIndicatorWrapper>
    </StyledTestimonialList>
  );
};

export default Testimonials;
