import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { themeVariables } from '../../theme';

interface SFSIProps {
  imagePosition: string;
  itemVisible: boolean;
}
const StyledFeaturedServiceItem = styled.div<SFSIProps>`
  max-width: 100%;
  opacity: 0;
  display: grid;
  gap: 20px;
  align-items: center;
  height: auto;
  overflow: hidden;
  grid-template-columns: ${({ imagePosition }) =>
    imagePosition === 'left' ? '2fr 2fr' : '2fr 2fr'};

  ${({ itemVisible }) =>
    itemVisible &&
    css`
      opacity: 1;
      animation: fadeInFeaturedItem ease 1s;

      @keyframes fadeInFeaturedItem {
        0% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}
`;

const StyledImage = styled(Img)`
  height: 300px;
  border-radius: ${themeVariables.values.imageBorderRadius};
`;
interface Props {
  serviceItem: any;
}

const FeaturedServiceItem = ({ serviceItem }: Props) => {
  const { title, featuredImage, body, imagePosition } = serviceItem.node;
  const itemRef = useRef<HTMLDivElement | null>(null);
  const [itemVisible, setItemVisible] = useState(false);
  const handleScroll = (e: any) => {
    if (itemRef.current) {
      var rect = itemRef.current.getBoundingClientRect();
      if (rect.top - document.documentElement.clientHeight < 0) {
        setItemVisible(true);
      }
    }
  };
  useEffect(() => {
    if (itemRef.current) {
      var rect = itemRef.current.getBoundingClientRect();
      if (rect.top - document.documentElement.clientHeight < 0) {
        setItemVisible(true);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <StyledFeaturedServiceItem
      itemVisible={itemVisible}
      ref={itemRef}
      imagePosition={imagePosition}
    >
      {imagePosition === 'left' && (
        <StyledImage fluid={featuredImage.childImageSharp.fluid} />
      )}
      <div>
        <h2> {title}</h2>
        <MDXProvider components={{}}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
      {imagePosition != 'left' && (
        <StyledImage fluid={featuredImage.childImageSharp.fluid} />
      )}
    </StyledFeaturedServiceItem>
  );
};

export default FeaturedServiceItem;
