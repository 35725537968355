import React from 'react';
import Img from 'gatsby-image';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import { device } from '../../theme';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
dayjs.extend(utc);

const StyledArticleItem = styled.div`
  display: flex;
  align-items: flex-start;
  @media ${device.laptop} {
    flex-flow: column;
  }
  @media ${device.tablet} {
    flex-flow: row;
  }
`;

const StyledArticleItemImageLink = styled(Link)`
  transition: filter 0.5s ease 0s;
  &:hover,
  &:focus {
    filter: brightness(0.8);
  }
  @media ${device.laptop} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: fit-content;
    margin-right: 30px;
  }
`;

interface SAIIProps {
  imageSize?: string;
}

const StyledArticleItemImage = styled(Img)<SAIIProps>`
  height: ${({ imageSize }) => (imageSize === 'large' ? '240px' : '120px')};
  width: ${({ imageSize }) => (imageSize === 'large' ? '160px' : '80px')};
  margin-right: 30px;
  display: flex;
  border-radius: 5px;
  @media ${device.laptop} {
    height: ${({ imageSize }) => (imageSize === 'large' ? '160px' : '150px')};
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  @media ${device.tablet} {
    height: ${({ imageSize }) => (imageSize === 'large' ? '240px' : '120px')};
    width: ${({ imageSize }) => (imageSize === 'large' ? '160px' : '80px')};
    margin-bottom: 0;
  }
`;

const StyledArticleHeader = styled.h3`
  margin: 0;
`;

const StyledDate = styled.span`
  font-size: 0.8rem;
`;

const StyledCategory = styled.span`
  font-size: 0.8rem;
`;

interface Props {
  article: any;
  sliceExcerpt?: number;
  imageSize?: string;
}

const ArticleItem = ({
  article,
  sliceExcerpt = 140,
  imageSize = 'small',
}: Props) => {
  const { title, featuredImage, date, slug, category } = article;
  const { excerpt } = article;
  const query = useStaticQuery(graphql`
    query getStoriDataArticle {
      storiThemeData {
        basePath
      }
    }
  `);
  const { basePath } = query.storiThemeData;
  return (
    <StyledArticleItem>
      {featuredImage && (
        <StyledArticleItemImageLink
          style={{ height: 'fit-content' }}
          to={`${basePath}/${slug}`}
          title={title}
        >
          <StyledArticleItemImage
            imageSize={imageSize}
            fluid={featuredImage.childImageSharp.fluid}
          />
        </StyledArticleItemImageLink>
      )}
      <article>
        {category && (
          <StyledCategory>
            <Link to={`${basePath}/category/${_.kebabCase(category)}`}>
              {category}
            </Link>
          </StyledCategory>
        )}
        <StyledArticleHeader style={{ margin: '0' }}>
          <Link to={`${basePath}/${slug}`}>{title}</Link>
        </StyledArticleHeader>
        <StyledDate>{dayjs.utc(date).format('MM/DD/YYYY')}</StyledDate>
        <p>
          {' '}
          {excerpt.slice(0, sliceExcerpt)}
          {sliceExcerpt <= 140 && '...'}
        </p>
      </article>
    </StyledArticleItem>
  );
};

export default ArticleItem;
