import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { themeVariables } from '../../theme';

const StyledProfilePicture = styled(Img)`
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: ${themeVariables.values.avatarBorderRadius};
  flex: 0 0 100px;
`;

interface Props {
  fluid: any;
}
const ProfilePicture = ({ fluid, ...rest }: Props) => {
  return <StyledProfilePicture fluid={fluid} {...rest} />;
};
export default ProfilePicture;
