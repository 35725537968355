import React from 'react';
import { ProfilePicture } from '../ProfilePicture';
import { LinkButton } from '../LinkButton';
import styled from 'styled-components';
import { device } from '../../theme';
import { graphql, useStaticQuery } from 'gatsby';

const StyledHero = styled.div`
  padding: 20px 0;
  margin-bottom: 0;
  align-self: center;
`;

const StyledHeroGrid = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  gap: 30px;
  @media ${device.laptop} {
    grid-template-columns: 1fr;
    text-align: center;
    justify-items: center;
  }
`;

const StyledHeroText = styled.h2`
  margin: 0 0 20px 0;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 120%;
`;

const Hero = () => {
  const query = useStaticQuery(graphql`
    query ProfileQuery {
      allStoriAuthorsYaml {
        edges {
          node {
            profilePicture {
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  originalImg
                  originalName
                  presentationHeight
                  presentationWidth
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  const { node: author } = query.allStoriAuthorsYaml.edges[0];
  return (
    <StyledHero>
      <StyledHeroGrid>
        <ProfilePicture fluid={author.profilePicture.childImageSharp.fluid} />
        <div>
          <StyledHeroText>
            My name is Jake, and I design calming spaces
          </StyledHeroText>
          <LinkButton primary to="/portfolio">
            See more of my work
          </LinkButton>
        </div>
      </StyledHeroGrid>
    </StyledHero>
  );
};

export default Hero;
